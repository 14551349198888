<template>
    <div>
      <PageLoader :storage="appLoading" />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #fff">{{ msg }}</span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #fff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-layout wrap justify-start pa-5>
                <v-flex xs12>
                  <span class="heading">Rescuer Details</span>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap justify-start>
                    <v-flex xs12>
                      <v-card flat>
                        <v-layout wrap justify-center pa-2>
                          <v-flex xs12 sm2 lg2 text-center align-self-center>
                            <v-img
                              contain
                              height="100%"
                              width="90%"
                              :src="mediaURL + rescueruserdata.photo"
                              fill-height
                              @click="dialog = true"
                              class="clickable"
                            ></v-img>
                          </v-flex>
                          <v-flex xs12 sm10 lg10>
                            <v-layout wrap justify-start>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="rescueruserdata.name"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Name</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ rescueruserdata.name }}
                                    <span v-if="!rescueruserdata.name">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="rescueruserdata.username"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Username</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ rescueruserdata.username }}
                                    <span v-if="!rescueruserdata.username"
                                      >-</span
                                    >
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="rescueruserdata.phone"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Phone</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ rescueruserdata.phone }}
                                    <span v-if="!rescueruserdata.phone">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="rescueruserdata.organization"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Organization</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ rescueruserdata.organization }}
                                    <span v-if="!rescueruserdata.organization"
                                      >-</span
                                    >
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="rescuerdata.education"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Education</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ rescuerdata.education }}
                                    <span v-if="!rescuerdata.education">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="rescuerdata.employment"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Employment</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ rescuerdata.employment }}
                                    <span v-if="!rescuerdata.employment">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="rescuerdata.adharnumber"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Aadhar Number</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ rescuerdata.adharnumber }}
                                    <span v-if="!rescuerdata.adharnumber">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="rescueruserdata.email"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Email</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ rescueruserdata.email }}
                                    <span v-if="!rescueruserdata.email">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="rescueruserdata.dob"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Date of Birth</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ formatDate(rescueruserdata.dob) }}
                                    <span v-if="!rescueruserdata.dob">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="rescuerdata.bloodGroup"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">BloodGroup</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ rescuerdata.bloodGroup }}
                                    <span v-if="!rescuerdata.bloodGroup">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="rescuerdata.permanentAddress"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Permanent Address</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ rescuerdata.permanentAddress }}
                                    <span v-if="!rescuerdata.permanentAddress"
                                      >-</span
                                    >
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="rescuerdata.presentAddress"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Present Address</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ rescuerdata.presentAddress }}
                                    <span v-if="!rescuerdata.presentAddress"
                                      >-</span
                                    >
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="rescuerdata.name"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">District</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ rescuerdata.district }}
                                    <span v-if="!rescuerdata.district">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="rescuerdata.districtCoordinator"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey"
                                    >District Coordinator</span
                                  >
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span
                                    class="itemValue"
                                    v-if="rescuerdata.districtCoordinator == true"
                                  >
                                    Yes
                                    <span
                                      v-if="
                                        rescuerdata.districtCoordinator == false
                                      "
                                      >No</span
                                    >
                                  </span>
                                  <span v-if="!rescuerdata.districtCoordinator"
                                    >-</span
                                  >
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="rescuerdata.offence"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Any Offense History</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span
                                    class="itemValue"
                                    v-if="rescuerdata.offence == true"
                                  >
                                    Yes
                                    <span v-if="rescuerdata.offence == false"
                                      >No</span
                                    >
                                  </span>
                                  <span v-if="!rescuerdata.offence">-</span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="
                                  rescuerdata.offenceDetails &&
                                  rescuerdata.offence === true
                                "
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Offense Details</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ rescuerdata.offenceDetails }}
                                    <span v-if="!rescuerdata.offenceDetails"
                                      >-</span
                                    >
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex xs12 sm6 lg4 md6 pt-2 v-if="rescuerdata">
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Rescue Started At</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ rescuerdata.rescueStartedAt }}
                                    <span v-if="!rescuerdata.rescueStartedAt"
                                      >-</span
                                    >
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex xs12 sm6 lg4 md6 pt-2 v-if="rescuerdata">
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Applied Date</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ formatDate(rescuerdata.applieddate)  }}
                                    <span v-if="!rescuerdata.applieddate">-</span>
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="rescuerdata.diseaseStatus"
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Any Disease</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span
                                    class="itemValue"
                                    v-if="rescuerdata.diseaseStatus == true"
                                  >
                                    Yes
                                    <span
                                      v-if="rescuerdata.diseaseStatus == false"
                                      >No</span
                                    >
                                  </span>
                                  <span v-if="!rescuerdata.diseaseStatus">-</span>
                                </v-flex>
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                                lg4
                                md6
                                pt-2
                                v-if="
                                  rescuerdata.diseaseDetails &&
                                  rescuerdata.diseaseStatus
                                "
                              >
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Disease</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ rescuerdata.diseaseDetails }}
                                    <span v-if="!rescuerdata.diseaseDetails"
                                      >-</span
                                    >
                                  </span>
                                </v-flex>
                              </v-flex>
                              <v-flex xs12 sm6 md4 pt-2 v-if="rescuerdata.adhar">
                                <v-layout wrap justify-start>
                                  <v-flex xs12 text-left>
                                    <span class="itemKey">Aadhar Image</span>
                                  </v-flex>
                                  <v-flex xs12 sm2 text-left>
                                    <v-img
                                      contain
                                      height="100%"
                                      width="100%"
                                      :src="mediaURL + rescuerdata.adhar"
                                      fill-height
                                      @click="dialogadhar = true"
                                      class="clickable"
                                    ></v-img>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 pt-lg-5>
                  <v-layout wrap justify-start>
                    <v-flex xs12 sm4 md4 lg4>
                      <v-btn
                        depressed
                        color="success"
                        @click="approveDialog = true"
                      >
                        <span>Approve Rescuer</span>
                      </v-btn>
                    </v-flex>
                    <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-sm-2 pl-md-2>
                      <v-btn depressed color="error" @click="rejectDialog = true">
                        <span>Reject Rescuer</span>
                      </v-btn>
                    </v-flex>
                    <v-flex xs12 sm4 md4 lg4 pl-sm-2 pl-md-2>
                      <!-- <v-btn
                        depressed
                        color="primary"
                        @click="trainingDialog = true"
                      >
                        <span>Move To Training List</span>
                      </v-btn> -->
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-dialog v-model="dialog" max-width="700">
        <v-card>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-layout wrap justify-center>
                <v-flex xs12 sm6>
                  <v-img
                    :src="mediaURL + rescueruserdata.photo"
                    contain
                    height="100%"
                    fill-height
                  ></v-img>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogadhar" max-width="700">
        <v-card>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-layout wrap justify-center>
                <v-flex xs12 sm6>
                  <v-img
                    :src="mediaURL + rescuerdata.adhar"
                    contain
                    height="100%"
                    fill-height
                  ></v-img>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
      <v-dialog v-model="approveDialog" max-width="500">
        <v-card>
          <v-card-title class="heading">Approve Rescuer</v-card-title>
          <v-card-text>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex xs12 class="subheading">
                    <span>Licence Number</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="licencenum"
                      placeholder="Licence Number"
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start>
                  <v-flex xs12 class="subheading">
                    <span>Category</span>
                    <v-select
                      outlined
                      dense
                      v-model="category"
                      :items="categoryitems"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start>
                  <v-flex xs12 class="subheading">
                    <span>Is District Coordinator</span>
                    <v-select
                      outlined
                      dense
                      v-model="districtCoordinator"
                      :items="districtCoordinatoritems"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="approveDialog = false"
              class="itemValue"
            >
              Cancel
            </v-btn>
            <v-btn
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="handlesubmit()"
              class="itemValue"
            >
              <span style="color: #fff">Approve</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="rejectDialog" max-width="500">
        <v-card>
          <v-card-title class="heading">Reject Rescuer</v-card-title>
          <v-card-text>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex xs12 class="subheading">
                    <span>Remarks</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="remarks"
                      placeholder="Enter Remarks"
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="rejectDialog = false"
              class="itemValue"
            >
              Cancel
            </v-btn>
            <v-btn
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="rejectUser()"
              class="itemValue"
            >
              <span style="color: #fff">Reject</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="trainingDialog" max-width="500">
        <v-card>
          <v-card-title class="heading">Move To Training List</v-card-title>
          <v-card-text
            >Are you sure you want to move this rescuer to the training
            list?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="trainingDialog = false"
              class="itemValue"
            >
              Cancel
            </v-btn>
            <v-btn
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="addTraining()"
              class="itemValue"
            >
              <span style="color: #fff">Submit</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
      <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
        rescuerdata: [],
        rescueruserdata: [],
        dialog: false,
        dialogadhar: false,
        approveDialog: false,
        rejectDialog: false,
        trainingDialog: false,
        remarks: "",
        licencenum: "",
        categoryitems: ["Official", "Public"],
        districtCoordinator: "",
        category: "",
        districtCoordinatoritems: ["Yes", "No"],
      };
    },
    beforeMount() {
      this.getData();
    },
    methods: {
      openEditDialog(item) {
        this.$router.push({
          path: "/edituser",
          query: {
            id: item._id,
          },
        });
      },
      getData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/rescuer/get",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            id: this.$route.query.id,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.rescuerdata = response.data.data;
                this.rescueruserdata = response.data.data.user;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            (this.ServerError = true), console.log(err);
          });
      },
      handlesubmit() {
        if (!this.licencenum) {
          this.msg = "Please Provide Licence Number.";
          this.showSnackBar = true;
          return;
        }
        if (this.districtCoordinator === "Yes") {
          this.districtCoordinatoredit = true;
        }
        if (this.districtCoordinator === "No") {
          this.districtCoordinatoredit = false;
        }
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/rescuer/approverescuer/",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            id: this.$route.query.id,
            rescuerCode: this.licencenum,
            districtCoordinator: this.districtCoordinatoredit,
            idType: this.category,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.msg = response.data.msg;
                this.showSnackBar = true;
                this.appLoading = false;
                this.approveDialog = false;
                this.$router
                .push({
                  path: "/traininglist",
                })
                .then(() => {
                  window.location.reload();
                });
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            (this.ServerError = true), console.log(err);
          });
      },
      updatepassword() {
        if (!this.password) {
          this.msg = "Please enter password to update.";
          this.showSnackBar = true;
          return;
        }
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/user/editpassword",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            id: this.$route.query.id,
            password: this.password,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.msg = "Password Updated Successfully.";
                this.showSnackBar = true;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            (this.ServerError = true), console.log(err);
          });
      },
      addTraining() {
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/rescuer/movetotraining/",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            id: this.$route.query.id,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.msg = "Moved to Training List.";
                this.showSnackBar = true;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            (this.ServerError = true), console.log(err);
          });
      },
      rejectUser() {
        if (!this.remarks) {
          this.msg = "Please provide remarks.";
          this.showSnackBar = true;
          return;
        }
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/rescuer/rejectrescuer/",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            id: this.$route.query.id,
            reason: this.remarks,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.msg = "Rescuer Request Rejected.";
                this.showSnackBar = true;
                this.appLoading = false;
                this.rejectDialog = false;
                this.$router
                .push({
                  path: "/traininglist",
                })
                .then(() => {
                  window.location.reload();
                });
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            (this.ServerError = true), console.log(err);
          });
      },
      formatDate(date) {
    if (!date) return '-';
    const [year, month, day] = date.slice(0, 10).split('-');
    return `${day}-${month}-${year}`;
  }
    },
  };
  </script>
      <style>
  .heading {
    font-family: poppinsregular;
    font-size: 20px;
    font-weight: 900;
  }
  .subheading {
    font-family: poppinsregular;
    font-size: 15px;
    font-weight: 500;
  }
  </style>